<template>
  <div>
    <portal to="page-top-title">New Api</portal>
    <portal to="page-top-right"/>

    <box-form action="create" data-prop="formData" url="/apis" redirect="apis-list" loader>
      <template slot="content">
        <row>
          <column-input>
            <form-group-input name="name" :model="formData.name" :min="3" required/>
          </column-input>
          <column-input>
            <form-group-select name="provider_id" title="Provider" :model="formData.provider_id"
                               :options="providers" :disabled="!providers.length" required
            />
          </column-input>
        </row>
        <row>
          <column-input>
            <form-group-input type="number" name="sending_rate" :model="formData.sending_rate" :min="0" required/>
          </column-input>
          <column-input :md="2">
            <form-group-check name="active" :model="formData.active"/>
          </column-input>
        </row>
        <row>
          <column-input :sm="12" :md="12">
            <form-group-multi-input name="credentials" :model="formData.credentials" required/>
          </column-input>
        </row>
      </template>
    </box-form>
  </div>
</template>

<script>
import BoxForm from '@/views/components/data/BoxForm'
import FormGroupInput from '@/views/components/form/FormGroupInput'
import FormGroupCheck from '@/views/components/form/FormGroupCheck'
import FormGroupSelect from '@/views/components/form/FormGroupSelect'
import FormGroupMultiInput from '@/views/components/form/FormGroupMultiInput'
import Form from '@/modules/utils/Form'

export default {
  name: 'ApisNew',
  metaInfo () {
    return { title: 'Apis | New' }
  },
  components: {
    BoxForm,
    FormGroupInput,
    FormGroupCheck,
    FormGroupSelect,
    FormGroupMultiInput
  },
  data () {
    return {
      providers: [],
      formData: {
        name: Form.types.input(),
        provider_id: Form.types.select(),
        sending_rate: Form.types.number(),
        active: Form.types.boolean(true),
        credentials: Form.types.multiInput()
      }
    }
  },
  computed: {
    provider () { return this.formData.provider_id.value }
  },
  watch: {
    provider (val) { this.providerChanged(val) }
  },
  created () {
    this.loadProviders()
  },
  methods: {
    loadProviders () {
      this.$http
        .get('/apis/providers', { params: { no_pages: 1 } })
        .then((res) => {
          const { data: { data } } = res

          this.providers = data.map((provider) => { return { id: provider.id, name: provider.name, creds: provider.credentials } })

          this.providerCheckSelected()
        })
    },
    providerCheckSelected () {
      const queryProviderId = Number(this.$route.query.provider)

      if (isNaN(queryProviderId)) return

      const provider = this.providers.find((provider) => provider.id === queryProviderId)

      if (provider) this.formData.provider_id = Form.types.select(provider)
    },
    providerChanged (val) {
      const { creds } = val

      this.formData.credentials = Form.types.multiInput(
        creds.map((cred) => { return { key: cred, value: Form.types.input() } })
      )
    }
  }
}
</script>
